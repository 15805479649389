import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})
export default class ReadOnlyUserDetails extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId = APP_UTILITIES.coreids().roleId;

    @Prop()
    userDetail!: any;

    @Prop()
    tabSelected!:string;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    editUser() {   
      this.$emit('editBxUser', this.userDetail);
    }

    resendInvite() {
      this.$emit('editBxUser', {email: this.userDetail.email, roleId: this.userDetail.role.id,index:this.userDetail.index});
    }

    close() {
      this.$emit(APP_CONST.CLOSE, 'read-user');
    }

    disableEditAction(data:any) {
      const {roleId} = APP_UTILITIES.coreids();
      return ( data.userRoles!=undefined &&  data.userRoles[0].roleId < roleId);
    }

    formatDate(value: string){
      return  value
        ? APP_UTILITIES.formatShortDate(value)
        : '--' ;
    }
    getUserRole(id: number){
      return APP_UTILITIES.getUserRole(id);
    }
    getStatusById(statusId: number) {
      return APP_UTILITIES.getStatusById(statusId);
    }
}